<template>
  <div class="notFound c_content_container">
    <div class="notFound_text c_inner">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <circle cx="15.5" cy="9.5" r="1.5" />
        <circle cx="8.5" cy="9.5" r="1.5" />
        <path
          d="M12 14c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5zm-.01-12C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        />
      </svg>
      <h1>404</h1>
      <p>페이지를 찾을 수 없습니다.</p>
      <p>요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨어요</p>
      <button @click="router.push('/')" class="c_btn_primary">
        홈으로 이동
      </button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "404",
  setup() {
    const router = useRouter();
    return { router };
  },
};
</script>

<style scoped>
.notFound {
  min-height: calc(100vh - var(--header-height));
}
.notFound .notFound_text {
  text-align: center;
  position: absolute;
  top: calc(50% - var(--header-height));
  left: 50%;
  transform: translate(-50%, -50%);
  word-break: keep-all;
  font-size: var(--font-size-large);
}
.notFound .notFound_text svg {
  display: inline-block;
  width: 8rem;
  height: 8rem;
}
.notFound .notFound_text h1 {
  font-size: var(--font-size-xx-large);
  font-weight: var(--font-bold);
  line-height: var(--line-height);
  color: var(--color-primary);
}
.notFound .notFound_text p {
  margin-bottom: 1rem;
}
.notFound .notFound_text button {
  width: 15rem;
  height: 4rem;
}
</style>
